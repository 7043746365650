var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',{staticClass:"app"},[_c('v-header',{staticStyle:{"filter":"invert(1)"}}),(_vm.isMobile)?_c('v-cover',{staticClass:"app__cover_video_mobile",attrs:{"src":"/V46 HAILEY - RTW - 15sec-16-9_.mp4","logo":"<h1>FALL 22 COLLECTION</h1><p>By Anthony Vaccarello</p>","ratio":375 / 760,"fetchPriority":'high',"lazy":'eager'}}):_c('v-cover',{staticClass:"app__cover_video_1",attrs:{"src":"https://res.cloudinary.com/neuvieme-page/video/upload/f_auto,vc_auto,w_1920,q_auto:good/v1659100441/modules-RTW/fall-22/Z46_HAILEY_-_RTW-_.mp4","logo":"<h1>FALL 22 COLLECTION</h1><p>By Anthony Vaccarello</p>","ratio":16 / 9,"fetchPriority":'high',"lazy":'eager'}}),_c('v-space',{style:({ height: 'var(--gutterfall22)' })}),(!_vm.isMobile)?_c('v-duo',{style:({ padding: _vm.isMobile ? '0 5.33333vw' : '0 20vw' }),attrs:{"slides":[
      {
        mediaLink: 'https://www.ysl.com/search?cgid=w-fall22-looks&look=2022_3_21_002',
        src:
          'https://res.cloudinary.com/neuvieme-page/image/upload/w_880,q_auto:good,f_auto/v1659106465/modules-RTW/fall-22/SAINT-LAURENT_FALL_22_55.jpg',
        ratio: 1120 / 1560,
      },
      {
        src:
          'https://res.cloudinary.com/neuvieme-page/image/upload/w_880,q_auto:good,f_auto/v1659106465/modules-RTW/fall-22/SAINT-LAURENT_FALL_22_13.jpg',
        mediaLink: 'https://www.ysl.com/search?cgid=men-fall-winter22-looks&look=2022_3_20_052',
        ratio: 1120 / 1560,
      } ]}}):_c('v-slideshow',{staticClass:"app__cover_video",attrs:{"gutters":"","slides":[
      {
        mediaLink: 'https://www.ysl.com/search?cgid=w-fall22-looks&look=2022_3_21_002',
        src:
          'https://res.cloudinary.com/neuvieme-page/image/upload/w_670,q_auto:good,f_auto/v1659106465/modules-RTW/fall-22/SAINT-LAURENT_FALL_22_55.jpg',
        ratio: 335 / 500,
      },
      {
        src:
          'https://res.cloudinary.com/neuvieme-page/image/upload/w_670,q_auto:good,f_auto/v1659106465/modules-RTW/fall-22/SAINT-LAURENT_FALL_22_13.jpg',
        ratio: 335 / 500,
      } ]}}),_c('v-space',{style:({ height: 'var(--gutterfall22)' })}),(!_vm.isMobile)?_c('v-slideshow',{staticClass:"app__cover_desktop",attrs:{"showMarginEffect":true,"slides":[
      {
        src:
          'https://res.cloudinary.com/neuvieme-page/image/upload/w_1920,f_auto,q_auto:good/v1659110113/modules-RTW/fall-22/SAINT-LAURENT_FALL_22_42.jpg',
          mediaLink:'https://www.ysl.com/search?cgid=w-fall22-looks&look=2022_3_21_002',
        ratio: 1920 / 1080,
      },
      {
        src:
          'https://res.cloudinary.com/neuvieme-page/image/upload/w_1920,f_auto,q_auto:good/v1659109681/modules-RTW/fall-22/SAINT-LAURENT_FALL_22_28.jpg',
        mediaLink:'https://www.ysl.com/search?cgid=men-fall-winter22-looks&look=2022_3_20_008',
        ratio: 1920 / 1080,
      } ]}}):_c('v-slideshow',{staticClass:"app__cover_video",attrs:{"showMarginEffect":"","slides":[
      {
        src:
          'https://res.cloudinary.com/neuvieme-page/image/upload/w_800,q_auto:good,f_auto/v1659110110/modules-RTW/fall-22/SAINT-LAURENT_FALL_22_29.jpg',
        ratio: 375 / 812,
      },
      {
        src:
          'https://res.cloudinary.com/neuvieme-page/image/upload/w_800,q_auto:good,f_auto/v1659109681/modules-RTW/fall-22/SAINT-LAURENT_FALL_22_46.jpg',
        ratio: 375 / 812,
      } ]}}),_c('v-space',{style:({ height: 'var(--gutterfall22)' })}),_c('v-cover',{staticClass:"app__cover_image",style:({ padding: _vm.isMobile ? '0 5.33333vw' : '0 35%' }),attrs:{"gutters":"","src":"https://res.cloudinary.com/neuvieme-page/image/upload/w_1920,q_auto:good,f_auto/v1659110113/modules-RTW/fall-22/SAINT-LAURENT_FALL_22_61.jpg","srcset":"https://res.cloudinary.com/neuvieme-page/image/upload/w_800,q_auto:good,f_auto/v1659110113/modules-RTW/fall-22/SAINT-LAURENT_FALL_22_61.jpg 1000w, https://res.cloudinary.com/neuvieme-page/image/upload/w_1920,q_auto:good,f_auto/v1659110113/modules-RTW/fall-22/SAINT-LAURENT_FALL_22_61.jpg 1920w","logo":"<h1>PHOTOGRAPHED</h1><p>By Gray Sorrenti</p>","ratio":2692 / 3600}}),_c('v-space',{style:({ height: 'var(--gutterfall22)' })}),(_vm.isMobile)?_c('v-cover',{staticClass:"app__cover_video",attrs:{"src":"/V11 ROMEO - RTW - Master-16-9.mp4","ratio":375 / 812}}):_c('v-cover',{staticClass:"app__cover_video",attrs:{"src":"https://res.cloudinary.com/neuvieme-page/video/upload/f_auto,vc_auto,w_1920,q_auto:good/v1659124323/modules-RTW/fall-22/Z11_ROMEO_-_RTW.mov","ratio":1920 / 1080}}),_c('v-space',{style:({ height: 'var(--gutterfall22)' })}),(!_vm.isMobile)?_c('v-duo',{style:({ padding: _vm.isMobile ? '0 5.33333vw' : '0 20vw' }),attrs:{"slides":[
      {
        src:
          'https://res.cloudinary.com/neuvieme-page/image/upload/w_880,q_auto:good,f_auto/v1659106465/modules-RTW/fall-22/SAINT-LAURENT_FALL_22_09.jpg',
          mediaLink:'https://www.ysl.com/search?cgid=w-fall22-looks&look=2022_3_21_038',
        ratio: 1120 / 1560,
      },
      {
        src:
          'https://res.cloudinary.com/neuvieme-page/image/upload/w_880,q_auto:good,f_auto/v1659106465/modules-RTW/fall-22/SAINT-LAURENT_FALL_22_49.jpg',
        mediaLink:'https://www.ysl.com/search?cgid=w-fall22-looks&look=2022_3_21_028',
        ratio: 1120 / 1560,
      } ]}}):_c('v-slideshow',{staticClass:"app__cover_video",attrs:{"gutters":"","slides":[
      {
        src:
          'https://res.cloudinary.com/neuvieme-page/image/upload/w_670,q_auto:good,f_auto/v1659106465/modules-RTW/fall-22/SAINT-LAURENT_FALL_22_09.jpg',
          mediaLink:'https://www.ysl.com/search?cgid=w-fall22-looks&look=2022_3_21_038',
        ratio: 335 / 500,
      },
      {
        src:
          'https://res.cloudinary.com/neuvieme-page/image/upload/w_670,q_auto:good,f_auto/v1659106465/modules-RTW/fall-22/SAINT-LAURENT_FALL_22_49.jpg',
        mediaLink:'https://www.ysl.com/search?cgid=w-fall22-looks&look=2022_3_21_028',
        ratio: 335 / 500,
      } ]}}),_c('v-space',{style:({ height: 'var(--gutterfall22)' })}),(!_vm.isMobile)?_c('v-cover',{staticClass:"app__cover_video",attrs:{"src":"https://res.cloudinary.com/neuvieme-page/image/upload/w_1920,q_auto:good,f_auto/v1659110112/modules-RTW/fall-22/SAINT-LAURENT_FALL_22_57.jpg","ratio":1920 / 1440,"mediaLink":"https://www.ysl.com/tote-bags/icare-maxi-shopping-bag-in-quilted-lambskin-698651AAANG1000.html?utm_source=outildigitalfall22&utm_medium=email&utm_content=main"}}):_c('v-cover',{staticClass:"app__cover_video_mobile",attrs:{"src":"https://res.cloudinary.com/neuvieme-page/image/upload/f_auto,w_1000/v1663147750/modules-RTW/fall-22/000906_YSL_09H_GRS_RGB.jpg","ratio":1125 / 2436,"mediaLink":"https://www.ysl.com/tote-bags/icare-maxi-shopping-bag-in-quilted-lambskin-698651AAANG1000.html?utm_source=outildigitalfall22&utm_medium=email&utm_content=main"}}),_c('div',{staticClass:"shop"},[_c('div',{staticClass:"shop__inner"},[_c('img',{attrs:{"src":"https://res.cloudinary.com/neuvieme-page/image/upload/v1659174590/modules-RTW/fall-22/FALL_22_COLLECTION_IN_STORE_AND_ON_YSL.COM.svg","alt":"collection store"}}),_c('div',{staticClass:"buttons"},[_c('a',{staticClass:"shop_btn",attrs:{"href":"https://www.ysl.com/boutique-en-ligne-femme/new---fall-22"}},[_vm._v("Shop women")]),_c('a',{staticClass:"shop_btn",attrs:{"href":"https://www.ysl.com/boutique-en-ligne-homme/new---fall-22"}},[_vm._v("Shop men")])])])]),_c('v-footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }