<template>
  <v-main class="app">
    <v-header style="filter: invert(1);" />
    <!-- header -->
    <v-cover
      v-if="isMobile"
      class="app__cover_video_mobile"
      src="/V46 HAILEY - RTW - 15sec-16-9_.mp4"
      :logo="`<h1>FALL 22 COLLECTION</h1><p>By Anthony Vaccarello</p>`"
      :ratio="375 / 760"
      :fetchPriority="'high'"
      :lazy="'eager'"
    />
    <v-cover
      v-else
      class="app__cover_video_1"
      src="https://res.cloudinary.com/neuvieme-page/video/upload/f_auto,vc_auto,w_1920,q_auto:good/v1659100441/modules-RTW/fall-22/Z46_HAILEY_-_RTW-_.mp4"
      :logo="`<h1>FALL 22 COLLECTION</h1><p>By Anthony Vaccarello</p>`"
      :ratio="16 / 9"
      :fetchPriority="'high'"
      :lazy="'eager'"
    />
    <!-- header -->
    <v-space :style="{ height: 'var(--gutterfall22)' }" />

    <v-duo
      v-if="!isMobile"
      :style="{ padding: isMobile ? '0 5.33333vw' : '0 20vw' }"
      :slides="[
        {
          mediaLink: 'https://www.ysl.com/en-en/search?cgid=w-fall22-looks&look=2022_3_21_002',
          src:
            'https://res.cloudinary.com/neuvieme-page/image/upload/w_880,q_auto:good,f_auto/v1659106465/modules-RTW/fall-22/SAINT-LAURENT_FALL_22_55.jpg',
          ratio: 1120 / 1560,
        },
        {
          src:
            'https://res.cloudinary.com/neuvieme-page/image/upload/w_880,q_auto:good,f_auto/v1659106465/modules-RTW/fall-22/SAINT-LAURENT_FALL_22_13.jpg',
            mediaLink: 'https://www.ysl.com/en-en/search?cgid=men-fall-winter22-looks&look=2022_3_20_052',
          ratio: 1120 / 1560,
        },
      ]"
    ></v-duo>

    <v-slideshow
      v-else
      class="app__cover_video"
      gutters
      :slides="[
        {
          mediaLink: 'https://www.ysl.com/en-en/search?cgid=w-fall22-looks&look=2022_3_21_002',
          src:
            'https://res.cloudinary.com/neuvieme-page/image/upload/w_670,q_auto:good,f_auto/v1659106465/modules-RTW/fall-22/SAINT-LAURENT_FALL_22_55.jpg',
          ratio: 335 / 500,
        },
        {
          src:
            'https://res.cloudinary.com/neuvieme-page/image/upload/w_670,q_auto:good,f_auto/v1659106465/modules-RTW/fall-22/SAINT-LAURENT_FALL_22_13.jpg',
          ratio: 335 / 500,
        },
      ]"
    />

    <v-space :style="{ height: 'var(--gutterfall22)' }" />

    <v-slideshow
      v-if="!isMobile"
      class="app__cover_desktop"
      :showMarginEffect="true"
      :slides="[
        {
          src:
            'https://res.cloudinary.com/neuvieme-page/image/upload/w_1920,f_auto,q_auto:good/v1659110113/modules-RTW/fall-22/SAINT-LAURENT_FALL_22_42.jpg',
          mediaLink:'https://www.ysl.com/en-en/search?cgid=w-fall22-looks&look=2022_3_21_002',
          ratio: 1920 / 1080,
        },
        {
          src:
            'https://res.cloudinary.com/neuvieme-page/image/upload/w_1920,f_auto,q_auto:good/v1659109681/modules-RTW/fall-22/SAINT-LAURENT_FALL_22_28.jpg',
          mediaLink:'https://www.ysl.com/en-en/search?cgid=men-fall-winter22-looks&look=2022_3_20_008',
          ratio: 1920 / 1080,
        },
      ]"
    />

    <v-slideshow
      v-else
      class="app__cover_video"
      showMarginEffect
      :slides="[
        {
          src:
            'https://res.cloudinary.com/neuvieme-page/image/upload/w_800,q_auto:good,f_auto/v1659110110/modules-RTW/fall-22/SAINT-LAURENT_FALL_22_29.jpg',
          ratio: 375 / 812,
        },
        {
          src:
            'https://res.cloudinary.com/neuvieme-page/image/upload/w_800,q_auto:good,f_auto/v1659109681/modules-RTW/fall-22/SAINT-LAURENT_FALL_22_46.jpg',
          ratio: 375 / 812,
        },
      ]"
    />

    <v-space :style="{ height: 'var(--gutterfall22)' }" />

    <v-cover
      gutters
      class="app__cover_image"
      src="https://res.cloudinary.com/neuvieme-page/image/upload/w_1920,q_auto:good,f_auto/v1659110113/modules-RTW/fall-22/SAINT-LAURENT_FALL_22_61.jpg"
      srcset="https://res.cloudinary.com/neuvieme-page/image/upload/w_800,q_auto:good,f_auto/v1659110113/modules-RTW/fall-22/SAINT-LAURENT_FALL_22_61.jpg 1000w, https://res.cloudinary.com/neuvieme-page/image/upload/w_1920,q_auto:good,f_auto/v1659110113/modules-RTW/fall-22/SAINT-LAURENT_FALL_22_61.jpg 1920w"
      :logo="`<h1>PHOTOGRAPHED</h1><p>By Gray Sorrenti</p>`"
      :ratio="2692 / 3600"
      :style="{ padding: isMobile ? '0 5.33333vw' : '0 35%' }"
    />

    <v-space :style="{ height: 'var(--gutterfall22)' }" />

    <v-cover
      v-if="isMobile"
      class="app__cover_video"
      src="/V11 ROMEO - RTW - Master-16-9.mp4"
      :ratio="375 / 812"
    />
    <v-cover
      v-else
      class="app__cover_video"
      src="https://res.cloudinary.com/neuvieme-page/video/upload/f_auto,vc_auto,w_1920,q_auto:good/v1659124323/modules-RTW/fall-22/Z11_ROMEO_-_RTW.mov"
      :ratio="1920 / 1080"
    />

    <v-space :style="{ height: 'var(--gutterfall22)' }" />

    <v-duo
      v-if="!isMobile"
      :style="{ padding: isMobile ? '0 5.33333vw' : '0 20vw' }"
      :slides="[
        {
          src:
            'https://res.cloudinary.com/neuvieme-page/image/upload/w_880,q_auto:good,f_auto/v1659106465/modules-RTW/fall-22/SAINT-LAURENT_FALL_22_09.jpg',
            mediaLink:'https://www.ysl.com/en-en/search?cgid=w-fall22-looks&look=2022_3_21_038',
          ratio: 1120 / 1560,
        },
        {
          src:
            'https://res.cloudinary.com/neuvieme-page/image/upload/w_880,q_auto:good,f_auto/v1659106465/modules-RTW/fall-22/SAINT-LAURENT_FALL_22_49.jpg',
          mediaLink:'https://www.ysl.com/en-en/search?cgid=w-fall22-looks&look=2022_3_21_028',
          ratio: 1120 / 1560,
        },
      ]"
    ></v-duo>

    <v-slideshow
      v-else
      class="app__cover_video"
      gutters
      :slides="[
        {
          src:
            'https://res.cloudinary.com/neuvieme-page/image/upload/w_670,q_auto:good,f_auto/v1659106465/modules-RTW/fall-22/SAINT-LAURENT_FALL_22_09.jpg',
            mediaLink:'https://www.ysl.com/en-en/search?cgid=w-fall22-looks&look=2022_3_21_038',
          ratio: 335 / 500,
        },
        {
          src:
            'https://res.cloudinary.com/neuvieme-page/image/upload/w_670,q_auto:good,f_auto/v1659106465/modules-RTW/fall-22/SAINT-LAURENT_FALL_22_49.jpg',
          mediaLink:'https://www.ysl.com/en-en/search?cgid=w-fall22-looks&look=2022_3_21_028',
          ratio: 335 / 500,
        },
      ]"
    />

    <v-space :style="{ height: 'var(--gutterfall22)' }" />

    <v-cover
      v-if="!isMobile"
      class="app__cover_video"
      src="https://res.cloudinary.com/neuvieme-page/image/upload/w_1920,q_auto:good,f_auto/v1659110112/modules-RTW/fall-22/SAINT-LAURENT_FALL_22_57.jpg"
      :ratio="1920 / 1440"
      mediaLink="https://www.ysl.com/en-en/tote-bags/icare-maxi-shopping-bag-in-quilted-lambskin-698651AAANG1000.html?utm_source=outildigitalfall22&utm_medium=email&utm_content=main"
    />
    <v-cover
      v-else
      class="app__cover_video_mobile"
      src="https://res.cloudinary.com/neuvieme-page/image/upload/,q_auto:good,f_auto/v1659168893/modules-RTW/fall-22/Rectangle_21202.jpg"
      :ratio="375 / 812"
      mediaLink="https://www.ysl.com/en-en/tote-bags/icare-maxi-shopping-bag-in-quilted-lambskin-698651AAANG1000.html?utm_source=outildigitalfall22&utm_medium=email&utm_content=main"
    />

    <div class="shop">
      <div class="shop__inner">
        <img
          src="https://res.cloudinary.com/neuvieme-page/image/upload/v1659174590/modules-RTW/fall-22/FALL_22_COLLECTION_IN_STORE_AND_ON_YSL.COM.svg"
          alt="collection store"
        />
        <div class="buttons">
          <a class="shop_btn" href="https://www.ysl.com/boutique-en-ligne-femme/new---fall-22">Shop women</a>
          <a class="shop_btn" href="https://www.ysl.com/boutique-en-ligne-homme/new---fall-22">Shop men</a>
        </div>
      </div>
    </div>

    <v-footer />
  </v-main>
</template>
<script>
export default {
  data() {
    return {
      isMobile: false
    }
  },
  mounted() {
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 768
    }
  }
}
</script>
<style lang="sass">
.header__content
  background-color: rgba(255,255,255,0);

@media screen and (min-width: 1024px) and (orientation: landscape)
  .app__cover_video_1,
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    .media__wrapper
      padding-top: 0 !important
      height: 100vh
    video
      height: 100vh !important;
      object-fit: cover;

.shop
  height: 100vh;
  max-height: 1080px
  @media screen and (min-width: 768px) and (orientation: portrait)
    max-height: calc(100vw / 1.77777777778)
  display: flex
  align-items: center
  justify-content: center
  img
    margin: auto;
    display: block;

  .buttons
    margin-top: 50px
    width: 350px
    display: flex
    justify-content: center
  .shop_btn
    text-decoration: none
    text-transform: uppercase
    font-size: 12px
    border: 1px solid #000
    padding: 13px 0
    text-align: center
    width: 162px
    font-family: 'HelveticaNeue', 'Arial', sans-serif;
    font-weight: 700
    &:hover
      background-color: #000
      color: #fff
      border: 1px solid #000
      transition: all .1s ease-in-out

  .shop_btn:first-child
    margin-right: 10px
</style>
