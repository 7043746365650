<template>
  <header
    class="header"
    :class="{ 'header--fixed': fixed, 'open' : navigationOpen }"
  >
    <div class="header__content">
      <a
        class="header__home"
        href="https://www.ysl.com/en-en"
        title="Saint laurent - Go to the Homepage"
        target="_blank"
        rel="noopener"
      >
        <h1 class="header__title">
          <img
            class="header__logo"
            alt="Saint laurent - Go to the Homepage"
            :src="require('../assets/images/logo.svg')"
            fetchPriority="high"
            loading="'eager'"
          >
        </h1>
      </a>
      <span
        class="header__burger"
        :class="{ 'header__burger--close': navigationOpen }"
        @click="toggleNavigation"
      ></span>
      <div
        class="header__navigation"
        :class="{ 'header__navigation--open': navigationOpen }"
      >
        <v-navigation
          class="header__navigation-left"
          :items="navigationItems"
        />
        <ul class="header__navigation-right">
          <li class="header__navigation-item">
            <a class="header__navigation-link header__navigation-link--medium" ref="noopener" href="https://www.ysl.com/en-en/login" target="_blank">
              My account
            </a>
            <a class="header__navigation-link header__navigation-link--small" ref="noopener" href="https://www.ysl.com/en-en/login" target="_blank">
              Sign in
            </a>
          </li>
          <li class="header__navigation-item">
            <a class="header__navigation-link" ref="noopener" href="https://www.ysl.com/en-en/displayname-faq" target="_blank">
              Client service
            </a>
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
import VNavigation from './VNavigation.vue'
import navigationItems from '../assets/data/navigation.json'

export default {
  name: 'VHeader',

  components: {
    VNavigation
  },

  props: {
    fixed: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      navigationOpen: false
    }
  },

  watch: {
    navigationOpen(navigationOpen) {
      document.body.style.overflow = navigationOpen ? 'hidden' : ''
    }
  },

  created() {
    this.navigationItems = navigationItems
  },

  methods: {
    toggleNavigation() {
      this.navigationOpen = !this.navigationOpen
    }
  }
}
</script>

<style lang="sass">
.header
  width: 100%
  top: 0
  z-index: 3
  position: absolute

  &.open
    overflow-x: inherit
    width: 100%
    .header__content
      background: rgba(255,255,255,1)

  &__content
    width: 100%
    display: flex
    align-items: center
    transition: transform .2s ease-out

  &--fixed &__content
    position: fixed

  &__home
    flex:
      grow: 0
      shrink: 0

  &__logo
    width: 185px
    height: 19px
    padding: 30px
    display: block

  &__navigation
    width: 100%
    top: 100%
    left: 0
    position: absolute

  &__navigation-right
    position: absolute
    top: 0
    right: 30px
    text-align: right

  &__navigation-item
    height: 11px
    font-size: 10px
    color: #000
    user-select: none
    text-transform: uppercase
    display: block

  &__navigation-link
    text-decoration: none
    color: #000
    display: inline-block

    &--small
      display: none

      @media screen and (max-width: 1023px)
        display: inline-block

    &--medium
      display: none

      @media screen and (min-width: 1024px)
        display: inline-block

  &__burger
    padding: 0 7px
    margin: 0 13px 0 auto
    cursor: pointer
    color: rgba(#000, .847)
    display: none
    font:
      family: 'icons'
      size: 16px

    &::before
      content: '\F10B'

    &:active
      color: #fff

    &--close::before
      content: '\F113'

  @media screen and (max-width: 1439px)
    &__navigation-left
      margin-top: 0

    &__navigation-right
      right: 20px

    &__logo
      // This is weird but it make sure the logo appear exactly like on ysl.com
      width: 165px
      height: 16.9375px
      padding: 20px

  @media screen and (max-width: 1023px)
    height: 56px

    &__content
      background-color: #fff
      position: fixed

    &__burger
      display: block

    &__navigation
      background-color: #fff
      height: calc(var(--vh, 1vh) * 100 - 56px)
      transform: translateX(105%)
      transition: transform .2s ease-in-out

      &--open
        transform: translateX(0)

    &__navigation-left
      width: 100%
      left: 0

    &__navigation-right
      position: relative
      margin-top: 23px
      text-align: left
      left: 0

    &__navigation-item
      height: 30px

    &__navigation-link
      padding: 10px 20px

    &__logo
      width: 151px
      height: 16px

  @media screen and (max-width: 767px)
    &__navigation-item
      font-size: 11px
</style>
